import PropTypes from 'prop-types';
import React from 'react';
import cx from "classnames";
import './Section.scss';

const Section = ({
  children,
  className,
  size,
  color,
  anchor,
  ...props
}) => {
  const classes = cx('section', {
		[`section--${className}`]: className,
	});
  return (
    <>
      <section
        className={classes}
        data-size={size}
        id={anchor}
        style={{ '--bgr-hero': `var(--color-${color})` }}
        {...props}
        >
        <div className="section__inner">
          {children}
        </div>
      </section>
    </>
)};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  anchor: PropTypes.string,
};

Section.defaultProps = {
  className: 'default',
  size: 'fixed',
  color: null,
  anchor: undefined,
};

export default Section;
