import React from "react";
import PropTypes from "prop-types";

const icons = {
  linkedin: {
    shape: (
    <>
      <path d="M72.2,99.7H9.9c-2.8,0-5,2.2-5,5v199.9c0,2.8,2.2,5,5,5h62.2c2.8,0,5-2.2,5-5V104.7C77.2,102,74.9,99.7,72.2,99.7z"/>
      <path d="M41.1,0.3C18.4,0.3,0,18.7,0,41.4c0,22.6,18.4,41,41.1,41c22.6,0,41-18.4,41-41C82.1,18.7,63.7,0.3,41.1,0.3z"/>
      <path d="M230.5,94.8c-25,0-43.5,10.7-54.7,23v-13c0-2.8-2.2-5-5-5h-59.6c-2.8,0-5,2.2-5,5v199.9c0,2.8,2.2,5,5,5h62.1
        c2.8,0,5-2.2,5-5v-98.9c0-33.3,9.1-46.3,32.3-46.3c25.3,0,27.3,20.8,27.3,48v97.2c0,2.8,2.2,5,5,5H305c2.8,0,5-2.2,5-5V195
        C310,145.4,300.5,94.8,230.5,94.8z"/>
    </>
    )
  },
  email: {
    shape: (
      <>
       <path d="M0,99.5c0-3.6,1.8-7.2,7.2-1.8c12.6,12.6,27,25.2,39.6,37.8s25.2,25.2,37.8,37.8c3.6,3.6,5.4,5.4,9,9
          c1.8,1.8,5.4,3.6,7.2,7.2c1.8,1.8,1.8,1.8,0,3.6c0,1.8-1.8,3.6-1.8,5.4c-1.8,1.8-3.6,1.8-5.4,3.6c-9,9-18,18-27,27l-1.8,1.8
          l-1.8,1.8c-9,7.2-16.2,16.2-25.2,23.4c-9,10.8-19.8,21.6-30.6,32.4c-1.8,1.8-3.6,3.6-5.4,0C0,286.8,0,283.2,0,281.4
          c0-57.6,0-115.2,0-174.7C0,104.9,0,101.3,0,99.5z"/>
        <path d="M165.7,76.1c45,0,90,0,135,0c1.8,0,5.4,0,7.2,0s3.6,0,5.4,1.8c1.8,1.8,1.8,3.6,0,5.4c-14.4,14.4-28.8,28.8-43.2,43.2
          c-3.6,3.6-5.4,5.4-9,9c-1.8,1.8-1.8,1.8-1.8,3.6c-23.4,21.6-45,45-68.4,68.4c-10.8,5.4-18,9-28.8,7.2c-7.2,0-12.6-3.6-18-9
          s-10.8-9-14.4-14.4c-36-36-72-72-109.8-108c-3.6-3.6-3.6-5.4,1.8-7.2c3.6,0,5.4,0,9,0C75.6,76.1,120.6,76.1,165.7,76.1z"/>
        <path d="M93.6,308.4c-12.6,0-23.4,0-36,0c-10.8,0-21.6-1.8-34.2-1.8c-1.8,0-9-1.8-5.4-5.4c14.4-14.4,28.8-28.8,43.2-43.2
          c14.4-14.4,28.8-28.8,43.2-43.2c3.6,0,7.2-3.6,10.8-7.2c1.8-1.8,3.6-1.8,3.6,0c3.6,3.6,7.2,7.2,12.6,12.6c9,9,19.8,14.4,34.2,14.4
          c12.6,0,23.4-3.6,32.4-12.6c3.6-3.6,9-9,12.6-12.6c3.6-1.8,3.6-3.6,5.4,0c16.2,16.2,32.4,32.4,48.6,48.6
          c14.4,14.4,30.6,28.8,45,43.2l1.8,1.8c1.8,3.6-3.6,3.6-5.4,3.6C288.1,308.4,117,308.4,93.6,308.4z"/>
        <path d="M331.3,191.4c0,1.8,0,3.6,0,5.4c0,28.8,0,55.8,0,84.6c0,3.6-1.8,12.6-7.2,9c-16.2-16.2-32.4-32.4-48.6-48.6
          c-16.2-14.4-30.6-30.6-46.8-45c-3.6-1.8-3.6-3.6,0-5.4c32.4-32.4,63-63,95.4-93.6c5.4-5.4,7.2,12.6,7.2,14.4
          C331.3,137.4,331.3,164.4,331.3,191.4z"/>
      </>
    ),
  },
  chevron: {
    shape: (
      <>
        <path d="M1.30397 9.5L12.1783 20.546L10.7641 21.9602L0 10.804L1.30397 9.5Z" fill="black"/>
        <path d="M10.764 0.039917L0 10.804L1.30465 12.1554L12.1783 1.45413L10.764 0.039917Z" fill="black"/>
      </>
    ),
  },
};

const Svg = ({ icon, size }) => {
  return <svg viewBox={size}>{icons[icon].shape}</svg>;
};

Svg.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  size: PropTypes.string,
};


export default Svg;
export { Svg };
