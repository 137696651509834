import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DataProvider } from "../utils/DataProvider";
import { gsap } from "gsap";
import Footer from "./Footer";
import Header from "./Header";
import "../styles/styles.scss";

const Layout = ({ children, location }) => {
  useEffect(() => {
    // some issues with deploying right now.
    // prevents flashing
    gsap.to("body", {
      duration: 0,
      css: { visibility: "visible" },
    });
  });

  return (
    <>
      <DataProvider>
        <Header location={location} />
        {children}
        <Footer location={location}>© {new Date().getFullYear()}, </Footer>
      </DataProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
};

export default Layout;
