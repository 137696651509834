import React, {useState} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby'
import useViewport from '../utils/useViewport'
import { useData } from "../utils/DataProvider";
import Nav from './Nav';

import './Header.scss';

const menuQuery = graphql`
  query Menu {
    data: allContentfulPage(sort: {fields: [title], order: DESC}) {
      edges {
        node {
          menuTitle
          slug
        }
      }
    }
    email: allContentfulSocial(filter: { name: { eq: "Email" } }) {
      edges {
        node {
          name
          url
        }
      }
    }
    nav: allContentfulNavigation {
      edges {
        node {
          links {
            title
            url
          }
        }
      }
    }
  }
`

const Header = () => {
  const [state, setState] = useData();
  const menuData = useStaticQuery(menuQuery);
  const mainMenu = menuData.data.edges.map(item => item.node);
  const navLinks = menuData.nav.edges[0].node.links.map(link => ({
    menuTitle: link.title,
    slug: link.url,
  }));
  const email = menuData.email.edges[0].node.url;
  const {width} =  useViewport();
  const isMobile = width < 768;
  const [menuState, setMenuState] = useState({
    initial: false,
    clicked: null,
  });

  const openMenu = action => setState({ ...state, isOverlayOpen: action});

  //toggle menu
  const handleMenu = () => {
    if (menuState.initial === false) {
      setMenuState({
        initial: null,
        clicked: true,
      });
      isMobile && openMenu(!state.isOverlayOpen)
    } else if (menuState.clicked === true) {
      setMenuState({
        clicked: !menuState.clicked
      });
      isMobile && openMenu(!state.isOverlayOpen)
    } else if (menuState.clicked === false) {
      setMenuState({
        clicked: !menuState.clicked
      });
      isMobile && openMenu(!state.isOverlayOpen)
    }
  };
  return (
    <>
      <Helmet>
        <body data-locked={state.isOverlayOpen} />
      </Helmet>
      <div className="header jsHeader">
        <div className="headerLogo" data-active={menuState.clicked}>
          <Link to="/">Henry Wong</Link><br/>
          <span className="more">(Brand Strategist, Creative Director, Author, Speaker)</span>
        </div>
        {!isMobile && (
          <div className="headerNav">
            {[...mainMenu, ...navLinks].map((item, index) => (
              <Link to={item?.slug} key={index} activeClassName="link--active" className="link">
                <span className="link__text">{item?.menuTitle}</span>
              </Link>
              )
            )}
            {/* <a href={email} target="_blank" rel="noreferrer noopener" className="link"><span className="link__text">Buy</span></a> */}
          </div>
        )}
        {isMobile && (
          <div className={`menu--${menuState.clicked ? 'on' : 'off'}`}>
            <div className="top-nav__icon">
              <button
                className="burger"
                onClick={handleMenu}
                aria-label="Menu"
                aria-controls="navigation"
                >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            <Nav setMenuState={setMenuState} email={email} />
          </div>
        )}
      </div>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
