import React from "react";
import PropTypes from "prop-types";
import Social from "./Social";
import Section from "./Section";
import "./Footer.scss"

const Footer = () => {
  return (
    <Section className="footer jsFooter" size="full">
      <div className="footer-bottom">
        Connect with Henry: <Social />
      </div>
    </Section>
  );
};

Footer.propTypes = {
  location: PropTypes.object,
};

export default Footer;
