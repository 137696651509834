import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import { useData } from "../utils/DataProvider";
import './Nav.scss';

const query = graphql`
  query Navigation {
    data: allContentfulPage(filter: { contentful_id: { eq: "4A0BgxDzuQlx2Zs3jcq6VN" } }) {
      edges {
        node {
          contentful_id
          sections {
            anchor
            anchorName
            color
            name
          }
        }
      }
    }
  }
`

const Nav = ({setMenuState, email}) => {
  const [state, setState] = useData();
  const menuData = useStaticQuery(query);
  const menu = menuData.data.edges[0].node.sections;

  const handleClick = (e) => {
    setState({ ...state, isOverlayOpen: false});
    setMenuState({clicked: false})
  }
  return (
    <nav className="nav nav-metas">
      <span className="is-accessible">Meta navigation</span>
      <ul className="nav-menu">
        {menu.map((item, index) => (
          <li key={index}>
            <a
              href={`/#${item.anchor}`}
              onClick={(e) => handleClick(e)}
              style={{ 'color': `var(--color-${item.color})` }}
              >{item.anchorName ? item.anchorName : item.name}</a>
          </li>
          )
        )}
        <li>
          <Link to="/chapters" onClick={() => handleClick()}>Chapter by Chapter</Link>
        </li>
        <li>
          <a href={email && email} target="_blank" rel="noreferrer noopener" onClick={() => handleClick}>Contact</a>
        </li>
      </ul>
    </nav>
  );
};

Nav.propTypes = {
  menu: PropTypes.array
}

export default Nav;
