import React from "react";
import { graphql, useStaticQuery } from 'gatsby'
import Svg from "./SVG.js";
import './Social.scss';

const socialQuery = graphql`
  query social {
    data: allContentfulSocial(sort: {fields: [name], order: DESC}) {
      edges {
        node {
          name
          url
          icon
          iconSize
        }
      }
    }
  }
`

const Social = () => {
  const socialData = useStaticQuery(socialQuery);
  const menu = socialData.data.edges.map(item => item.node);
  return (
    <div className="social">
      {menu.map((item, index) => (
        <a
          key={index}
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
          className="link"
        >
          <Svg size="0 0 331.3 309.7" icon={item.icon}/>
        </a>
      ))}
    </div>
  );
};
export default Social;
